<template>
  <div class="invoice member-table-container container_media">
    <div class="content myCenter_table">
      <div class="operation-container">
        <el-form :model="ruleForm" :rules="rules" @submit.native.prevent ref="ruleForm" label-width="80px">
          <el-form-item label="我的兑换" prop="code">
            <el-input v-model.trim="ruleForm.code" placeholder="请输入兑换码" clearable size="small" maxlength="6" />
          </el-form-item>
          <el-button type="download" size="small" plain @click="submit" :loading="submitLoading">立即兑换</el-button>
        </el-form>
        <p class="authorized-download-tips"><i
            class="el-icon-warning-outline warning-icon"></i><span>注意：请填写获取到的兑换码进行兑换商品</span>
        </p>
      </div>
      <el-table ref="multipleTable" :data="list" header-cell-class-name="table_header" :cell-style="TableRowStyle">
        <el-table-column label="序号" type="index" align="center" width="50px" />
        <el-table-column label="商品名称" prop="goods_name" align="center" width="auto">
          <template slot-scope="{ row: { goods_type, goods_name } }">
            {{ `${goods_type == '1' ? '个人' : '企业'}-${goods_name}` }}
          </template>
        </el-table-column>
        <el-table-column label="兑换码" prop="code" align="center" width="auto" />
        <el-table-column label="兑换时间" prop="receive_at" align="center" width="auto" />
      </el-table>
      <!-- 分页器 -->
      <Pagination position="center" :hiddenPage="true" style="margin-top: 40px;" v-if="total > 0"
        :limit.sync="listQuery.page_size" :page.sync="listQuery.page" @pagination="handlePageChange" :total="total" />
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { exchangeList, exchangeGenCode } from '@/api/member';
const validateCDKEY = (rule, value, callback) => {
  const regZh = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g; // 中文
  const regEn = /[-~#（）|【-】· (){}+=*^&%$@!.,，。<>;:：；‘’“”、'"?`]/g; // 特殊字符
  if (!value) {
    callback(new Error('请输入有效兑换码'))
  } else if (regZh.test(value) || regEn.test(value)) {
    callback(new Error('输入格式不正确'))
  } else {
    callback()
  }
}
export default {
  components: {
    Pagination
  },
  data() {
    return {
      ruleForm: {
        code: ''
      },
      submitLoading: false,
      rules: {
        code: [
          { required: true, validator: validateCDKEY, trigger: 'blur' },
        ]
      },
      list: [],
      total: 0,
      listQuery: {
        page: 1,
        page_size: 10
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    TableRowStyle({ rowIndex }) {
      let tableRowStyle = "";
      if (rowIndex % 2 !== 0) {
        tableRowStyle = tableRowStyle + "background-color: #FAFAFA;border-radius: 2px;";
      }
      return tableRowStyle;
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.exchange()
        }
      });
    },
    async exchange() {
      try {
        this.submitLoading = true
        const res = await exchangeGenCode({ ...this.ruleForm })
        if (res.code === 0) {
          this.$message.success('兑换成功！')
          this.ruleForm.code = '';
          this.getList()
          this.$store.dispatch('user/getUserInfo');
        }
        this.submitLoading = false
      } catch (error) {
        this.submitLoading = false
        console.log(error)
      }
    },
    async getList() {
      try {
        const res = await exchangeList({ ...this.listQuery })
        if (res.code === 0) {
          this.list = res.data.list
          this.total = res.data.total
        }
      } catch (error) {
        console.log(error)
      }
    },
    handlePageChange(e) {
      this.listQuery.page = e.page;
      this.getList();
      // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }
}
</script>

<style lang="scss" scoped>
.invoice {
  padding: 40px 0;

  .operation-container {
    margin-bottom: 12px;

    .tip {
      margin-bottom: 15px;
      font-size: 13px;
    }

    .el-input {
      width: 300px;
      margin-right: 20px;
      height: 30px;
      display: block;
    }

    .el-button {
      height: 30px;
      width: 100px;
    }

    /deep/.el-form {
      display: flex;
      align-items: center;

      .el-form-item {
        margin-bottom: 0;

        .el-form-item__label {
          font-size: 15px;
          font-weight: 500;
          text-align: left;

          &::before {
            content: '';
          }
        }
      }
    }
  }

  .content {
    @include size(100%, auto);
    padding: 24px 36px;
    border-radius: 4px;
    box-sizing: border-box;
    background: #FFFFFF;
    margin-top: 24px;
  }

  .authorized-download-tips {
    width: 100%;
    height: 32px;
    padding-left: 26px;
    background: rgba(255, 142, 20, 0.1);
    border: 1px solid #FF8E14;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    display: flex;
    align-items: center;
    margin: 20px 0 0px;

    .warning-icon {
      margin-right: 6px;
      font-size: 12px;
      color: #FF8E14;
    }
  }
}
</style>
